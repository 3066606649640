// Generated by Framer (915daba)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-gXGC3"

const variantClassNames = {w7Q90fqmd: "framer-v-y8ggi2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, cVCy1w3CH: click ?? props.cVCy1w3CH} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cVCy1w3CH, MPF9pxOKNTQT7BvBlu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "w7Q90fqmd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1t9ajqn = activeVariantCallback(async (...args) => {
if (cVCy1w3CH) {const res = await cVCy1w3CH(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-y8ggi2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"w7Q90fqmd"} ref={ref ?? ref1} style={{...style}}><Link href={MPF9pxOKNTQT7BvBlu} motionChild nodeId={"VBlcPGLMb"}><motion.a className={"framer-1x6arn0 framer-vbllox"} data-framer-name={"Left"} data-highlight layoutDependency={layoutDependency} layoutId={"VBlcPGLMb"} onTap={onTap1t9ajqn} style={{backdropFilter: "blur(5px)", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, WebkitBackdropFilter: "blur(5px)"}}><motion.div className={"framer-1p82agc"} layoutDependency={layoutDependency} layoutId={"MYYq5genq"}><SVG className={"framer-46wf3e"} data-framer-name={"Vector"} fill={"rgba(0,0,0,1)"} intrinsicHeight={8} intrinsicWidth={12} layoutDependency={layoutDependency} layoutId={"mytnncUcZ"} svg={"<svg width=\"12\" height=\"8\" viewBox=\"-1 -1 12 8\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M5 5.99999L0 0.999988L0.7 0.299988L5 4.59999L9.3 0.299988L10 0.999988L5 5.99999Z\" fill=\"white\"/>\n</svg>\n"} withExternalLayout/></motion.div></motion.a></Link></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gXGC3.framer-vbllox, .framer-gXGC3 .framer-vbllox { display: block; }", ".framer-gXGC3.framer-y8ggi2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 40px; justify-content: center; overflow: visible; padding: 12px 16px 12px 0px; position: relative; width: min-content; }", ".framer-gXGC3 .framer-1x6arn0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; max-width: 1440px; overflow: hidden; padding: 6px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-gXGC3 .framer-1p82agc { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; max-width: 1440px; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-gXGC3 .framer-46wf3e { flex: none; height: 6px; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gXGC3.framer-y8ggi2, .framer-gXGC3 .framer-1x6arn0, .framer-gXGC3 .framer-1p82agc { gap: 0px; } .framer-gXGC3.framer-y8ggi2 > *, .framer-gXGC3 .framer-1x6arn0 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-gXGC3.framer-y8ggi2 > :first-child, .framer-gXGC3 .framer-1x6arn0 > :first-child, .framer-gXGC3 .framer-1p82agc > :first-child { margin-left: 0px; } .framer-gXGC3.framer-y8ggi2 > :last-child, .framer-gXGC3 .framer-1x6arn0 > :last-child, .framer-gXGC3 .framer-1p82agc > :last-child { margin-right: 0px; } .framer-gXGC3 .framer-1p82agc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"cVCy1w3CH":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerebhk2vQrz: React.ComponentType<Props> = withCSS(Component, css, "framer-gXGC3") as typeof Component;
export default Framerebhk2vQrz;

Framerebhk2vQrz.displayName = "Icon wrapper";

Framerebhk2vQrz.defaultProps = {height: 40, width: 38};

addPropertyControls(Framerebhk2vQrz, {cVCy1w3CH: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(Framerebhk2vQrz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})